import {
  BackendQueryKeys,
  useTypeSafeMutation,
  useTypeSafeQuery,
} from '@oresundsbron/api';
import {
  Banner,
  Box,
  Button,
  Link,
  LoadingButton,
  Spinner,
  Typography,
} from '@oresundsbron/bridge-ui';
import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BankIDIcon, LoadingIcon } from './Icon';
import * as utils from './state-utils';
import { setAuthToken } from '../../../../stores/auth';
import { pipe } from 'fp-ts/lib/function';
import {
  chain,
  fromNullable,
  map,
  match,
  tryCatch,
} from 'fp-ts/lib/TaskOption';
import { getOrElse, map as mapO } from 'fp-ts/lib/Option';
import { tap, tapTO } from '@oresundsbron/utilities';
import { isBackendError } from '../../../../lib/errors';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { lookup } from 'fp-ts/lib/Record';
import { Eq } from 'fp-ts/lib/string';

export const BankIDForm: FC<{
  onGoToLogin: () => void;
  onLoginSuccess: () => Promise<void>;
  ns?: string;
}> = ({ onGoToLogin, onLoginSuccess, ns }) => {
  const [retryLoading, toggleRetryLoading] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [poll, togglePoll] = useState(false);
  const [authPoll, toggleAuthPoll] = useState(false);
  const [authError, setAuthError] = useState<string>();
  const { t } = useTranslation([ns || '', 'common'], { nsMode: 'fallback' });
  const queryClient = useQueryClient();
  const { query } = useRouter();

  const { data: order, refetch: refetchOrder } = useTypeSafeQuery(
    BackendQueryKeys.BankIDOrder(),
    (q) => q.Auth.bankID.order
  );

  // Toggle polling jobs
  useEffect(() => {
    toggleAuthPoll(!!order);
    togglePoll(!!order);
  }, [order]);

  // polling for qr-code
  const { data } = useTypeSafeQuery(
    BackendQueryKeys.BankIDPoll(),
    (q) => q.Auth.bankID.poll({ orderReference: order?.orderReference || '' }),
    {
      refetchInterval: 1000,
      refetchIntervalInBackground: false,
      enabled: poll,
      retry: false,
      onError: () => {
        togglePoll(false);
      },
    }
  );

  // polling for collect status
  const { data: collectData } = useTypeSafeQuery(
    ['bankid', 'collect'],
    (q) =>
      q.Auth.bankID.collect({ orderReference: order?.orderReference || '' }),
    {
      refetchInterval: 1000,
      refetchIntervalInBackground: false,
      enabled: authPoll,
      cacheTime: 0,
      retry: false,
      onError: (err) => {
        toggleAuthPoll(false);
        togglePoll(false);
        if (isBackendError(err) && err.code === 404) {
          setAuthError('authentication.bankid.error.unknownUser');
          return;
        }

        setAuthError('authentication.bankid.error.internal');
      },
    }
  );

  // launch bankid on device
  const { refetch } = useTypeSafeQuery(
    ['bankid', 'launch'],
    (q) =>
      q.Auth.bankID.launch({ orderReference: order?.orderReference || '' }),
    {
      enabled: false,
      retry: false,
      onError: () => {
        toggleAuthPoll(false);
        setAuthError('authentication.bankid.error.internal');
      },
    }
  );

  const { mutateAsync } = useTypeSafeMutation(
    ['bankid', 'cancel'],
    (req) => req.Auth.bankID.cancel
  );

  const waiting = utils.waitingForInteraction(collectData);
  const userIntraction = utils.userInteraction(collectData);

  const internalError = utils.internalError(collectData);
  const cancelled = utils.cancelled(collectData);
  const expired = utils.expired(collectData);
  const unknownError = utils.unknownError(collectData);
  const certError = utils.certError(collectData);
  const startFailed = utils.startFailed(collectData);
  const error =
    internalError ||
    cancelled ||
    expired ||
    unknownError ||
    certError ||
    startFailed ||
    !!authError;

  const complete = utils.complete(collectData);

  useEffect(() => {
    if (userIntraction) {
      togglePoll(false);
    }
  }, [userIntraction]);

  useEffect(() => {
    if (error || complete) {
      toggleAuthPoll(false);
    }
  }, [complete, error]);

  useEffect(() => {
    if (complete) {
      setAuthToken(
        (collectData as utils.Complete).token || '',
        pipe(
          query,
          lookup('source'),
          mapO((v) => Eq.equals(v as string, 'pay')),
          getOrElse(() => false)
        )
      );
      onLoginSuccess();
    }
  }, [complete, collectData, onLoginSuccess, query]);

  const _onGoToLogin = useCallback(() => {
    toggleAuthPoll(false);
    togglePoll(false);
    mutateAsync({ orderReference: order?.orderReference || '' });
    onGoToLogin();
  }, [mutateAsync, onGoToLogin, order?.orderReference]);

  const launch = useCallback(() => {
    toggleLoading(true);
    return pipe(
      tryCatch(() => refetch()),
      map((q) => q.data),
      chain(fromNullable),
      tapTO(({ autoStartToken }) => {
        document.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
      }),
      match(
        () => {},
        () => {}
      ),
      tap(() => toggleLoading(false))
    )();
  }, [refetch]);

  // remove cache when leaving component
  useEffect(
    () => () => {
      setAuthError(undefined);
      queryClient.removeQueries(BackendQueryKeys.BankIDOrder());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Box className="-mt-6 flex items-center justify-center text-5xl">
        <BankIDIcon />
      </Box>
      {!authError ? (
        <>
          {waiting ? (
            <>
              <Box className="mb-4">
                <Trans
                  ns={[ns || '', 'common']}
                  nsMode="fallback"
                  i18nKey="authentication.bankid.interactions.none"
                >
                  <Typography className="text-center" />
                </Trans>
              </Box>
              {data ? (
                <Image
                  unoptimized
                  src={data?.qrData}
                  width={200}
                  height={200}
                  alt=""
                  className="mx-auto"
                />
              ) : null}
            </>
          ) : null}
          {userIntraction ? (
            <>
              <LoadingIcon className="mx-auto" />
              <Typography className="mb-4 text-center">
                {t('authentication.bankid.interactions.started')}
              </Typography>
            </>
          ) : null}
          {complete ? (
            <Box className="my-8 flex items-center justify-center">
              <Spinner
                className="text-4xl text-primary-600"
                label={t('action.loading')}
              />
            </Box>
          ) : null}
        </>
      ) : null}
      <Box className="mt-8">
        {!!error ? (
          <Banner color="negative">
            {!!authError ? (
              <Trans
                ns={[ns || '', 'common']}
                nsMode="fallback"
                i18nKey={authError}
              >
                <Typography gutter />
              </Trans>
            ) : null}
            {cancelled ? (
              <Typography className="text-center">
                {t('authentication.bankid.error.cancelled')}
              </Typography>
            ) : null}
            {expired ? (
              <Typography className="text-center">
                {t('authentication.bankid.error.expired')}
              </Typography>
            ) : null}
            {unknownError || internalError ? (
              <Typography className="text-center">
                {t('authentication.bankid.error.unknown')}
              </Typography>
            ) : null}
            {certError ? (
              <Typography className="text-center">
                {t('authentication.bankid.error.cert')}
              </Typography>
            ) : null}
            {startFailed ? (
              <Typography className="text-center">
                <Trans
                  ns={[ns || '', 'common']}
                  nsMode="fallback"
                  i18nKey="authentication.bankid.error.startFailed"
                >
                  <Link href="https://install.bankid.com" target="_blank" />
                </Trans>
              </Typography>
            ) : null}
          </Banner>
        ) : null}
      </Box>
      <Box className="mt-8 flex flex-col gap-4 md:gap-6">
        {!error ? (
          <LoadingButton
            loadingLabel={t('action.loading')}
            isLoading={loading}
            type="button"
            intent="solid"
            className="justify-center"
            disabled={userIntraction || complete}
            onClick={launch}
          >
            {t('authentication.bankid.sameDevice')}
          </LoadingButton>
        ) : (
          <LoadingButton
            type="button"
            intent="text"
            color="neutral"
            className="justify-center"
            loadingLabel={t('action.loading')}
            isLoading={retryLoading}
            onClick={async () => {
              toggleRetryLoading(true);
              try {
                await refetchOrder();
                toggleAuthPoll(true);
                togglePoll(true);
                setAuthError(undefined);
              } catch {}
              toggleRetryLoading(false);
            }}
            disabled={userIntraction || complete}
          >
            {t('action.tryAgain')}
          </LoadingButton>
        )}
      </Box>
      <Box className="flex flex-col gap-4 md:gap-6">
        <Box className="relative mb-2 mt-6 flex justify-center md:mt-8">
          <Typography className="z-10 bg-white px-4" intent="label" size="lg">
            {t('authentication.login.or')}
          </Typography>
          <Box as="hr" className="absolute left-0 right-0 top-[50%]" />
        </Box>
        <Button
          intent="outlined"
          color="neutral"
          className="[&>span:first-child]:relative [&>span:first-child]:flex [&>span:first-child]:w-full [&>span:first-child]:flex-1 [&>span:first-child]:items-center [&>span:first-child]:justify-center"
          disabled={loading}
          onClick={_onGoToLogin}
        >
          {t('authentication.bankid.goToLogin')}
        </Button>
      </Box>
    </>
  );
};
