import { BackendError } from '@oresundsbron/api';
import { ValidationResult } from '@oresundsbron/use-form';
import { filter, reduce, matchW, map as mapA } from 'fp-ts/lib/ReadonlyArray';
import { pipe } from 'fp-ts/lib/function';
import {
  fromNullable,
  match,
  map as mapO,
  chain as chainO,
  fromPredicate,
  getOrElseW,
} from 'fp-ts/lib/Option';
import {
  chain,
  TaskOption,
  map,
  alt,
  of,
  sequenceArray,
  some,
} from 'fp-ts/lib/TaskOption';
import { isEmpty, lookup, toEntries } from 'fp-ts/lib/Record';

export const CREDIT_EVAL_YELLOW = 'CREDIT_EVAL_YELLOW';
export const CREDIT_EVAL_RED = 'CREDIT_EVAL_RED';

export type CreditEvalError = 'CREDIT_EVAL_RED' | 'CREDIT_EVAL_YELLOW';

export const isBackendError = (x: unknown): x is BackendError =>
  ((!!x && typeof (x as BackendError).error === 'string') ||
    Array.isArray((x as BackendError).validationError)) &&
  typeof (x as BackendError).code === 'number';

export const isCreditError = (x: unknown): x is CreditEvalError =>
  x === CREDIT_EVAL_RED || x === CREDIT_EVAL_YELLOW;

const mergeValidationResult =
  <T extends ValidationResult<unknown> | undefined>(main: T) =>
  (sec: T) =>
    pipe(
      sec,
      fromNullable,
      match(
        () => main,
        (err) => (main ? { ...err, ...main } : err)
      )
    );

const inSequence =
  <T>(
    fns: ((
      errors?: ValidationResult<T>
    ) => TaskOption<ValidationResult<T> | undefined>)[]
  ) =>
  (
    errors?: ValidationResult<T>
  ): TaskOption<ValidationResult<T> | undefined> => {
    let res: TaskOption<ValidationResult<T> | undefined> = some(errors);

    for (let i = 0; i < fns.length; i++) {
      res = pipe(
        res,
        chain((err) =>
          pipe(
            err,
            fns[i],
            map(removeUndefinedValues),
            map(mergeValidationResult(err)),
            alt(() => some(err))
          )
        )
      );
    }

    return res;
  };

export const chainValidations = <T>(
  ...fns: ((
    errors?: ValidationResult<T>
  ) => TaskOption<ValidationResult<T> | undefined>)[]
) =>
  chain<ValidationResult<T> | undefined, ValidationResult<T> | undefined>(
    (errors) =>
      pipe(
        errors,
        inSequence(fns),
        alt(() => of(errors))
      )
  );

export const isFieldErrorEmpty =
  <T>(key: string) =>
  (errors?: ValidationResult<T>) =>
    pipe(
      errors,
      fromNullable,
      chainO(lookup(key)),
      chainO(fromPredicate((x) => x !== undefined && x !== null)),
      match(
        () => true,
        () => false
      )
    );

export const chainValidationTO = <T>(
  fn: TaskOption<ValidationResult<T> | undefined>
) =>
  chain<ValidationResult<T> | undefined, ValidationResult<T> | undefined>(
    (errors) =>
      pipe(
        fn,
        map(mergeValidationResult(errors)),
        alt(() => of(errors))
      )
  );

const removeUndefinedValues = <T>(obj: ValidationResult<T> | undefined) =>
  pipe(
    obj,
    fromNullable,
    mapO((err) => toEntries(err as Record<string, unknown>)),
    mapO(filter(([_, v]) => v !== undefined)),
    mapO(
      reduce({} as ValidationResult<T>, (res, [k, v]) => ({ ...res, [k]: v }))
    ),
    chainO(fromPredicate((errs) => !isEmpty(errs))),
    getOrElseW(() => undefined)
  );

export const sequenceValidateTO = <T>(
  fns: TaskOption<ValidationResult<T> | undefined>[]
) =>
  chainValidationTO(
    pipe(
      fns,
      sequenceArray,
      map(mapA(removeUndefinedValues)),
      map(filter((res) => res !== undefined)),
      map(
        matchW(
          () => undefined,
          (arr) =>
            pipe(
              arr,
              reduce({} as ValidationResult<T>, (res, err) =>
                mergeValidationResult(res)(err as ValidationResult<T>)
              )
            )
        )
      ),
      map((err) =>
        isEmpty(err as Record<string, undefined>) ? undefined : err
      )
    )
  );
