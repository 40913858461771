import { pipe } from 'fp-ts/lib/function';
import { reduce } from 'fp-ts/lib/Array';
import { of, Task } from 'fp-ts/lib/Task';
import {
  chain,
  fromPredicate,
  tryCatch,
  map,
  getOrElseW,
} from 'fp-ts/lib/TaskOption';
import { matchW, map as mapO } from 'fp-ts/lib/Option';
import {
  ParseParams,
  SafeParseError,
  SafeParseReturnType,
  ZodError,
  ZodIssue,
  ZodObjectDef,
} from 'zod';
import { TFunction } from 'next-i18next';
import { lookup, toEntries } from 'fp-ts/lib/Record';
import { match } from 'fp-ts/lib/boolean';
import { ValidationResult } from '@oresundsbron/use-form';

type SafeParseAsyncFn<Input, Output> = (
  data: unknown,
  params?: Partial<ParseParams> | undefined
) => Promise<SafeParseReturnType<Input, Output>>;

const isValidationError = <T>(x: unknown): x is SafeParseError<T> =>
  !!x && typeof x === 'object' && (x as SafeParseError<T>).success === false;

const getPath = (issue: ZodIssue) => issue.path.join('.');

const toValidationMap =
  (t: TFunction) =>
  <T>(error: ZodError<T>): ValidationResult<T> | undefined =>
    pipe(
      error.issues,
      reduce({} as ValidationResult<T>, (res, issue) => ({
        ...res,
        [getPath(issue)]: pipe(
          res,
          lookup(getPath(issue)),
          mapO((curr) => (Array.isArray(curr) ? curr : [curr])),
          matchW(
            () => t(issue.message),
            (curr) => [...curr, t(issue.message)]
          )
        ),
      }))
    );

export const validateT =
  <T, B>(fn: SafeParseAsyncFn<T, B>, t: TFunction = (str: string) => str) =>
  (data: T): Task<ValidationResult<T> | undefined> =>
    pipe(
      tryCatch(() => fn(data)),
      chain((res) => fromPredicate(isValidationError)(res)),
      map((res) => res.error),
      chain(fromPredicate((err) => !err.isEmpty)),
      map(toValidationMap(t)),
      getOrElseW(() => of(undefined))
    );

export const validate =
  <T, B>(fn: SafeParseAsyncFn<T, B>, t: TFunction = (str: string) => str) =>
  (data: T): Promise<ValidationResult<T> | undefined> =>
    validateT<T, B>(fn, t)(data)();

const getRequiredFields = (def: ZodObjectDef, withKey = '') =>
  pipe(
    def.shape(),
    toEntries,
    reduce(
      {} as Record<string, boolean>,
      (res, [key, field]): Record<string, boolean> =>
        pipe(
          !!field._def.schema?._def?.shape,
          match(
            () => ({
              ...res,
              [withKey ? `${withKey}.${key}` : key]: !field.isOptional(),
            }),
            () => ({
              ...res,
              ...getRequiredFields(field._def.schema?._def, key),
            })
          )
        )
    )
  );
