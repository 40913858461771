import {
  BackendQueryKeys,
  useTypeSafeMutation,
  useTypeSafeQuery,
} from '@oresundsbron/api';
import {
  Box,
  Button,
  LoadingButton,
  Input,
  Banner,
  Typography,
} from '@oresundsbron/bridge-ui';
import { submitWithValidation, useForm } from '@oresundsbron/use-form';
import { tapO, tapTE } from '@oresundsbron/utilities';
import {
  customerContractParser,
  loginFormParser,
  passwordLoginParser,
} from '@oresundsbron/validators';
import { flow, identity, pipe } from 'fp-ts/lib/function';
import { match as matchO, map as mapO, getOrElse } from 'fp-ts/lib/Option';
import { chain, map, matchW, tryCatchK } from 'fp-ts/lib/TaskEither';
import { useTranslation } from 'next-i18next';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { useRecaptchaToken } from '../../../../hooks/useRecaptchaToken';
import { validate } from '../../../../lib/zod';
import { authenticate } from '../../../../stores/auth';
import { BankIDIcon } from '../BankID';
import { matchError, toLoginError } from './utils';
import { useRouter } from 'next/router';
import { Eq } from 'fp-ts/lib/string';
import { lookup } from 'fp-ts/lib/Record';
import { DefaultLink } from '~/components/Links/DefaultLink';

export type LoginBody = z.input<typeof passwordLoginParser>;
export type CustomerContract = z.infer<typeof customerContractParser>;

export type EIdentity = 'BankID';

export const LoginForm: FC<{
  onLoginSuccess: () => Promise<void>;
  onGoToEIdentity: (eID: EIdentity) => void;
  onGoToForgotPassword: () => void;
  mouseOverEIdentity: (eID: EIdentity) => void;
  ns?: string;
}> = ({
  onLoginSuccess,
  onGoToEIdentity,
  mouseOverEIdentity,
  onGoToForgotPassword,
  ns,
}) => {
  const usernameRef = useRef<HTMLInputElement>();
  const [loading, toggleLoading] = useState(false);
  const { t } = useTranslation([ns || '', 'common'], { nsMode: 'fallback' });
  const [error, setError] = useState<string>('');
  const getToken = useRecaptchaToken();
  const { query } = useRouter();
  const { mutateAsync } = useTypeSafeMutation(
    ['login'],
    ({ Auth }) => Auth.login
  );

  const onError = useCallback(
    (e: unknown) =>
      pipe(
        e,
        toLoginError,
        matchError({
          user: () => 'authentication.login.error.userInput',
          internal: () => 'authentication.login.error.internal',
        }),
        tapO(flow(t, setError)),
        matchO(
          () => {},
          () => {}
        )
      ),
    [t, setError]
  );

  const { refetch } = useTypeSafeQuery(
    BackendQueryKeys.CustomerInformation(),
    ({ Customer }) => Customer.get,
    { enabled: false, retry: 0, staleTime: Infinity }
  );

  const onSubmit = useCallback(
    (credentials: LoginBody) => {
      toggleLoading(true);

      return pipe(
        getToken(),
        map((rcToken) => ({ ...credentials, rcToken })),
        chain(tryCatchK(mutateAsync, identity)),
        tapTE(({ token, customerType }) =>
          authenticate(
            token,
            customerType,
            credentials.username,
            pipe(
              query,
              lookup('source'),
              mapO((source) => Eq.equals(source as string, 'pay')),
              getOrElse(() => false)
            )
          )
        ),
        chain(tryCatchK(() => refetch(), identity)),
        tapTE(() => onLoginSuccess()),
        matchW(
          (e) => {
            onError(e);
            toggleLoading(false);
          },
          () => {}
        )
      )();
    },
    [getToken, mutateAsync, onError, onLoginSuccess, refetch, query]
  );

  const {
    registerField,
    validate: handleValidate,
    handleSubmit,
    getError,
    isValid,
    resetFieldErrors,
    transformData,
  } = useForm<LoginBody>({
    namespace: 'login',
    onValidate: validate(loginFormParser.spa, t),
    onSubmit,
  });

  useEffect(() => {
    usernameRef.current && usernameRef.current.focus();

    return () => {
      resetFieldErrors();
      transformData(() => ({ username: '', password: '', rcToken: '' }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => toggleLoading(false), []);

  return (
    <>
      <Box
        as="form"
        className="flex flex-col gap-4 md:gap-6"
        noValidate
        onSubmit={submitWithValidation(handleValidate, handleSubmit)}
      >
        {error ? (
          <Banner color="negative">
            <Typography>{error}</Typography>
          </Banner>
        ) : null}
        <Input
          {...registerField('username')}
          error={getError('username')}
          label={t('form.labels.username')}
          ref={usernameRef}
          required
        />
        <Input
          {...registerField('password', { type: 'password' })}
          error={getError('password')}
          label={t('form.labels.password')}
          required
        />
        <Box>
          <DefaultLink href="/account/login/forgot" intent={'label'}>
            {t('authentication.login.goToForgotPassword')}
          </DefaultLink>
        </Box>
        <LoadingButton
          loadingLabel={t('action.loading')}
          isLoading={loading}
          type="submit"
          disabled={!isValid}
          className="mt-4 justify-center"
        >
          {t('action.login')}
        </LoadingButton>
      </Box>
      <Box className="flex flex-col gap-4 md:gap-6">
        <Box className="relative mb-2 mt-6 flex justify-center md:mt-8">
          <Typography className="z-10 bg-white px-4" intent="label" size="lg">
            {t('authentication.login.or')}
          </Typography>
          <Box as="hr" className="absolute left-0 right-0 top-[50%]" />
        </Box>
        <Button
          intent="outlined"
          color="neutral"
          className="[&>span:first-child]:relative [&>span:first-child]:flex [&>span:first-child]:w-full [&>span:first-child]:flex-1 [&>span:first-child]:items-center [&>span:first-child]:justify-center"
          disabled={loading}
          onClick={() => onGoToEIdentity('BankID')}
          onMouseOver={() => mouseOverEIdentity('BankID')}
        >
          <BankIDIcon className="absolute -left-4 sm:left-0" />
          {t('action.login_bankid')}
        </Button>
      </Box>
    </>
  );
};
